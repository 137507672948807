import { Button, CircularProgress } from '@mui/material';
import {
  DatagridConfigurable,
  Pagination,
  ReferenceManyField,
  TabbedForm,
  TabbedFormViewProps,
  TextField,
} from 'react-admin';
import { useRunScanMutation } from '../utils';
import { ShopScanResource } from '../../../Entities/ShopScan';
import TzDateField from '../../../Components/TzDate/TzDateField';
import { ReactElement } from 'react';

export default function ScannerTab(props: TabbedFormViewProps): ReactElement {
  const { handleRunScan, isLoading } = useRunScanMutation();

  return (
    <TabbedForm.Tab label="Scanner" {...props}>
      <Button variant="contained" color="primary" onClick={handleRunScan} disabled={isLoading}>
        {isLoading ? <CircularProgress size={24} /> : 'Run Scan'}
      </Button>
      <ReferenceManyField<ShopScanResource>
        reference="shop_scans"
        sort={{ field: 'start_date', order: 'DESC' }}
        target="merchant.id"
        pagination={<Pagination />}
      >
        <DatagridConfigurable bulkActionButtons={false}>
          <TextField label="Scan ID" source="relationships.scan.data.id" />
          <TzDateField label="Start Date" showTime source="attributes.start_date" />
          <TzDateField label="End Date" showTime source="attributes.end_date" />
          <TextField label="Total Offers" source="attributes.total_offers" />
          <TextField label="New Offers" source="attributes.new_offers" />
          <TextField label="Recovered Offers" source="attributes.recovered_offers" />
          <TextField label="Removed Offers" source="attributes.removed_offers" />
          <TextField label="Updated Offers" source="attributes.updated_offers" />
          <TextField label="Duplicate Offers" source="attributes.duplicate_offers" />
          <TextField label="Unchanged Offers" source="attributes.unchanged_offers" />
        </DatagridConfigurable>
      </ReferenceManyField>
    </TabbedForm.Tab>
  );
}
