import { ReactElement } from 'react';
import MappingRuleResource from '../../Entities/MappingRules';
import { useRecordContext, useRefresh } from 'react-admin';
import { Grid, LinearProgress } from '@mui/material';
import SelfPaginatingBulkAction from '../../Components/SelfPaginatingBulkAction';

export default function ActionPanel(): ReactElement {
  const record = useRecordContext<MappingRuleResource>();
  const refresh = useRefresh();

  if (!record) {
    return <LinearProgress />;
  }

  return (
    <Grid container spacing={2}>
      <Grid container item xs={12} xl={3} spacing={2}>
        <Grid item xs={12}>
          <SelfPaginatingBulkAction
            buttonLabel={(total) => `Approve ${total} offers`}
            changes={{ attributes: { is_approved: true } }}
            disabled={typeof record.relationships.merchant?.data?.id === 'undefined'}
            filter={{
              equals: {
                merchant: { id: record.relationships.merchant?.data?.id },
                external_category: record.attributes.external_category,
                is_approved: false,
              },
            }}
            refresh={refresh}
            resource="offers"
          />
        </Grid>
        <Grid item xs={12}>
          <SelfPaginatingBulkAction
            buttonLabel={(total) => `Assign ${total} unassigned offers to category`}
            changes={
              record.relationships.category?.data?.id
                ? {
                    relationships: {
                      category: {
                        data: {
                          id: record.relationships.category?.data.id,
                          type: 'categories',
                        },
                      },
                    },
                  }
                : { relationships: { category: { data: null } } }
            }
            disabled={typeof record.relationships.category?.data?.id === 'undefined'}
            filter={{
              equals: {
                merchant: { id: record.relationships.merchant?.data?.id },
                external_category: record.attributes.external_category,
              },
              isNull: { category: true },
            }}
            resource="offers"
            refresh={refresh}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} xl={3} spacing={2}>
        <Grid item xs={12}>
          <SelfPaginatingBulkAction
            buttonLabel={(total) => `Reassign ${total} offers to category`}
            changes={{
              relationships: {
                category: {
                  data: {
                    id: record.relationships.category?.data?.id,
                    type: 'categories',
                  },
                },
              },
            }}
            color="warning"
            filter={{
              equals: {
                merchant: { id: record.relationships.merchant?.data?.id },
                external_category: record.attributes.external_category,
              },
              and: {
                not: {
                  isNull: { category: true },
                  equals: {
                    category: {
                      id: record.relationships.category?.data?.id ?? '0',
                    },
                  },
                },
              },
            }}
            refresh={refresh}
            resource="offers"
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} xl={3} spacing={2}>
        <Grid item xs={12}>
          <SelfPaginatingBulkAction
            buttonLabel={(total) => `Unapprove ${total} offers`}
            changes={{ attributes: { is_approved: false } }}
            color="error"
            filter={{
              equals: {
                merchant: { id: record.relationships.merchant?.data?.id },
                external_category: record.attributes.external_category,
                is_approved: true,
              },
            }}
            refresh={refresh}
            resource="offers"
          />
        </Grid>
        <Grid item xs={12}>
          <SelfPaginatingBulkAction
            buttonLabel={(total) => `Unassign ${total} offers from category`}
            color="error"
            resource="offers"
            refresh={refresh}
            filter={{
              equals: {
                category: { id: record.relationships.category?.data?.id },
                merchant: { id: record.relationships.merchant?.data?.id },
                external_category: record.attributes.external_category,
              },
            }}
            changes={{ relationships: { category: { data: null } } }}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} xl={3} spacing={2}>
        <Grid item xs={12}>
          <SelfPaginatingBulkAction
            buttonLabel={(total) => `Delete ${total} offers matching rule`}
            changeType="delete"
            color="error"
            confirm={(total) =>
              `Are you sure you want to delete the ${total} offers that match this rule? These offers will be deleted and may be reingested in the next Scan.`
            }
            disabled={typeof record.relationships.merchant?.data?.id === 'undefined'}
            filter={{
              equals: {
                merchant: { id: record.relationships.merchant?.data?.id },
                external_category: record.attributes.external_category,
              },
            }}
            refresh={refresh}
            resource="offers"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
