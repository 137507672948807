import {
  fetchUtils,
  GetListParams,
  GetListResult,
  HttpError,
  Identifier,
  RaRecord,
} from 'react-admin';
import { JsonApiCollectionResponse, JsonApiResource, JsonApiResourceLinkage } from './JsonApiTypes';
import ExpiringCache from '../ExpiringCache';
import { createSort, defaultHeaders, withIncludes } from './utils';
import createFilter from './JsonApiFiltering';
import type { AuthProvider } from './AuthProvider';
import { merge } from 'lodash';
import { DataProvider } from '.';

export default function getList(
  apiUrl: string,
  httpClient = fetchUtils.fetchJson,
  getOne: ReturnType<typeof DataProvider>['getOne'],
  canAccess: typeof AuthProvider.canAccess = async () => true,
  cache: ExpiringCache | undefined = undefined,
) {
  async function ensureIncluded<T extends RaRecord & JsonApiResource>(
    response: GetListResult<T>,
    link?: null | JsonApiResourceLinkage<T['id']>,
  ): Promise<GetListResult<T>> {
    if (link && !response.data.some((d) => d.id === link.id)) {
      const item = await getOne<T>(link.type, { id: link.id });
      response.data.push(item.data);
    }

    return response;
  }

  return async <T extends RaRecord<Identifier>>(
    resource: string,
    params: GetListParams,
  ): Promise<GetListResult<T & JsonApiResource>> => {
    const { filter, meta } = params;
    const filterIsAllIds = Object.keys(filter).every((k) => k === 'id') && filter.id;

    const data: (T & JsonApiResource)[] = [];

    // Use cached data where available
    if (filterIsAllIds) {
      const originalIds: Identifier | Identifier[] = params.filter.id;

      if (Array.isArray(originalIds)) {
        const ids: Identifier[] = [];

        originalIds.forEach((id) => {
          const cacheKey = `${resource}/${id}`;
          const cached = cache?.get(cacheKey);

          if (cached) {
            data.push(cached as T & JsonApiResource);
          } else {
            ids.push(id);
          }
        });

        filter.id = ids;

        if (filter.id.length === 0) {
          return ensureIncluded(
            {
              data,
              total: data.length,
              pageInfo: { hasNextPage: false, hasPreviousPage: false },
            },
            meta?.ensureIncluded,
          );
        }
      } else {
        const cacheKey = `${resource}/${originalIds}`;
        const cached = cache?.get(cacheKey);

        if (cached) {
          data.push(cached as T & JsonApiResource);
          return ensureIncluded(
            { data, total: 1, pageInfo: { hasNextPage: false, hasPreviousPage: false } },
            meta?.ensureIncluded,
          );
        }
      }
    }

    const query = await withIncludes(resource, canAccess);

    if (params.pagination) {
      query.set('page[number]', String(params.pagination.page));
      query.set('page[size]', String(params.pagination.perPage));
    }
    if (params.sort) {
      query.set('sort', createSort(params.sort));
    }

    const filterString = createFilter(filter);

    if (filterString) {
      query.set('filter', filterString);
    }

    const url = `${apiUrl}/${resource}?${query}`;

    try {
      const response = await httpClient(url, { headers: defaultHeaders, signal: params.signal });
      const result = response.json as JsonApiCollectionResponse;
      const total = Number(result.meta?.total ?? 0) + data.length;

      const fetchedData = (result.data ?? []) as (T & JsonApiResource)[];
      data.push(...fetchedData);

      const hasNextPage = result.links?.next !== null;
      const hasPreviousPage = result.links?.prev !== null;

      if (filterIsAllIds) {
        fetchedData.forEach((d) => {
          const key = `${resource}/${d.id}`;
          const existing = cache?.get(key) ?? {};
          const incoming = merge(existing, d);
          cache?.set(`${resource}/${d.id}`, incoming);
        });
      }

      if (result.included) {
        result.included.forEach((d) => {
          const key = `${resource}/${d.id}`;
          const existing = cache?.get(key) ?? {};
          const incoming = merge(existing, d);
          cache?.set(`${resource}/${d.id}`, incoming);
        });
      }

      return ensureIncluded(
        { data, total, pageInfo: { hasNextPage, hasPreviousPage } },
        meta?.ensureIncluded,
      );
    } catch (error) {
      if (error instanceof Error && error.name === 'AbortError') {
        throw error;
      }
      throw new HttpError(
        (error as HttpError).body?.errors[0].detail ?? 'Error fetching records',
        (error as HttpError).body?.status ?? 500,
        (error as HttpError).body,
      );
    }
  };
}
