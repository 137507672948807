import { ReferenceManyInput } from '@react-admin/ra-relationships';
import { SelectInput, SimpleFormIterator, TextInput, required } from 'react-admin';
import {
  FEED_DISCRIMINATOR_CSV,
  FEED_DISCRIMINATOR_XML,
  FEED_DISCRIMINATOR_XML_INDEX,
  FEED_FIELD_DISCRIMINATOR_CHOICES,
  FEED_FIELD_DISCRIMINATOR_CSV,
  FEED_FIELD_DISCRIMINATOR_XML,
  FEED_FIELD_INDEX_CHOICES,
  FEED_FIELD_TYPE_CHOICES,
} from '../../Merchant/utils';
import { FeedFieldResource } from '../../../Entities/FeedField';
import { ReactElement } from 'react';
import { useWatch } from 'react-hook-form';
import { match } from 'ts-pattern';

function validateRequiredFeedFieldsExist(fields: FeedFieldResource[]): string | undefined {
  const fieldIds = fields.map((field) => field.attributes.field_id);
  // Required fields:
  // - 100: Product URL
  // - 101: Unique ID
  // - 102: Product Name
  // - 107: Current Price
  const requiredFields = [100, 101, 102, 107];
  const missingFields = requiredFields.filter((fieldId) => !fieldIds.includes(fieldId));

  if (missingFields.length > 0) {
    const missingFieldNames = missingFields.map(
      (fieldId) =>
        FEED_FIELD_TYPE_CHOICES.find((choice) => choice.id === fieldId)?.name ?? `${fieldId}`,
    );

    return `Required fields missing: ${missingFieldNames.join(', ')}`;
  }

  return undefined;
}

function validateNoDuplicateFieldIds(fields: FeedFieldResource[]): string | undefined {
  const fieldIds = fields.map((field) => field.attributes.field_id);
  const uniqueFieldIds = new Set(fieldIds);

  if (fieldIds.length !== uniqueFieldIds.size) {
    return 'Cannot have duplicate field configurations in the feed config. Each field must be unique.';
  }

  return undefined;
}

function validateAllFieldsHaveSpecifier(fields: FeedFieldResource[]): string | undefined {
  const allFieldsHaveSpecifier = fields.every(
    (field) => field.attributes.field_index || field.attributes.xpath,
  );

  if (!allFieldsHaveSpecifier) {
    return 'All fields must have a specifier (Field Index or XPath)';
  }

  return undefined;
}

export default function FeedFieldForm(): ReactElement {
  const feedDiscriminator = useWatch({ name: 'attributes.discriminator' });

  return (
    <ReferenceManyInput
      reference="feed_fields"
      target="relationships.feed.data.id"
      label={false}
      validate={[
        validateAllFieldsHaveSpecifier,
        validateNoDuplicateFieldIds,
        validateRequiredFeedFieldsExist,
      ]}
      defaultValue={[
        { attributes: { field_id: 100 } },
        { attributes: { field_id: 101 } },
        { attributes: { field_id: 102 } },
        { attributes: { field_id: 107 } },
      ]}
    >
      <SimpleFormIterator inline disableReordering>
        <SelectInput
          defaultValue={match(feedDiscriminator)
            .with(FEED_DISCRIMINATOR_CSV, () => FEED_FIELD_DISCRIMINATOR_CSV)
            .with(FEED_DISCRIMINATOR_XML, () => FEED_FIELD_DISCRIMINATOR_XML)
            .with(FEED_DISCRIMINATOR_XML_INDEX, () => FEED_FIELD_DISCRIMINATOR_XML)
            .otherwise(() => undefined)}
          source="attributes.discriminator"
          choices={FEED_FIELD_DISCRIMINATOR_CHOICES}
          style={{ display: 'none' }}
        />
        <SelectInput
          label="Field Type"
          source="attributes.field_id"
          choices={FEED_FIELD_TYPE_CHOICES}
          isRequired
          validate={[required()]}
        />
        {feedDiscriminator === FEED_DISCRIMINATOR_CSV && (
          <SelectInput
            label="Field Index"
            source="attributes.field_index"
            choices={FEED_FIELD_INDEX_CHOICES}
            isRequired
            validate={[required()]}
          />
        )}
        {(feedDiscriminator === FEED_DISCRIMINATOR_XML ||
          feedDiscriminator === FEED_DISCRIMINATOR_XML_INDEX) && (
          <TextInput
            source="attributes.xpath"
            label="XPath"
            helperText="XPath to the field in the XML"
            isRequired
            validate={[required()]}
          />
        )}
        <TextInput label="Match Expression (Regex)" source="attributes.expression" />
        <TextInput label="Rewrite Format" source="attributes.rewrite_format" />
      </SimpleFormIterator>
    </ReferenceManyInput>
  );
}
