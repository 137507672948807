import { ReactElement } from 'react';
import { TabbedFormViewProps, TabbedForm, TextInput, maxLength } from 'react-admin';
import JsonApiHasOneReferenceInput from '../../../Components/JsonApiHasOneReferenceInput';
import { RegistrationResource } from '../../../Entities/Registration';

export default function AddressTab(props: TabbedFormViewProps): ReactElement {
  return (
    <TabbedForm.Tab label="Address" {...props}>
      <TextInput label="Street Address" source="attributes.address" validate={maxLength(255)} />
      <TextInput label="State" source="attributes.state" validate={maxLength(255)} />
      <TextInput label="Post Code" source="attributes.postal_code" validate={maxLength(255)} />
      <TextInput label="City" source="attributes.city" validate={maxLength(255)} />
      <TextInput label="Country" source="attributes.country" validate={maxLength(255)} />
      <JsonApiHasOneReferenceInput<RegistrationResource, 'region'>
        label="Region"
        optionText="attributes.name"
        reference="regions"
        source="relationships.region"
      />
    </TabbedForm.Tab>
  );
}
