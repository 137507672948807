import React, { ReactElement } from 'react';
import { useWatch } from 'react-hook-form';

export interface ConditionalInputProps {
  children: ReactElement;
  field: string;
  condition?: (value: unknown) => boolean;
}

export default function ConditionalInput({
  children,
  field,
  condition = (value: unknown) => typeof value !== 'undefined',
}: ConditionalInputProps): ReactElement | null {
  const value = useWatch({ name: field });

  if (condition(value)) {
    return React.cloneElement(children);
  }

  return null;
}
