import {
  fetchUtils,
  GetManyParams,
  GetManyResult,
  HttpError,
  Identifier,
  RaRecord,
} from 'react-admin';
import ExpiringCache from '../ExpiringCache';
import { defaultHeaders, transformIds, withIncludes } from './utils';
import createFilter from './JsonApiFiltering';
import { JsonApiCollectionResponse, JsonApiResource } from './JsonApiTypes';
import type { AuthProvider } from './AuthProvider';
import { merge } from 'lodash';

export default function getMany(
  apiUrl: string,
  httpClient = fetchUtils.fetchJson,
  canAccess: typeof AuthProvider.canAccess = async () => true,
  cache: ExpiringCache | undefined = undefined,
) {
  return async <T extends RaRecord<Identifier>>(
    resource: string,
    params: GetManyParams,
  ): Promise<GetManyResult<T & JsonApiResource>> => {
    const ids: Identifier[] = [];
    const data: (T & JsonApiResource)[] = [];

    transformIds(params.ids).forEach((id) => {
      const cacheKey = `${resource}/${id}`;
      const cached = cache?.get(cacheKey);

      if (cached) {
        data.push(cached as T & JsonApiResource);
      } else {
        ids.push(id);
      }
    });

    if (ids.length === 0) {
      return { data };
    }

    const query = await withIncludes(resource, canAccess);
    const filter = createFilter({ id: ids });

    if (filter) {
      query.set('filter', filter);
    }

    query.set('page[size]', String(ids.length));

    const url = `${apiUrl}/${resource}?${query}`;

    try {
      const response = await httpClient(url, { headers: defaultHeaders, signal: params.signal });
      const result = response.json as JsonApiCollectionResponse;

      result.data?.forEach((d) => {
        const key = `${resource}/${d.id}`;
        const existing = cache?.get(key) ?? {};
        const incoming = merge(existing, d);
        cache?.set(`${resource}/${d.id}`, incoming);
        data.push(incoming as T & JsonApiResource);
      });

      if (result.included) {
        result.included.forEach((i) => {
          const key = `${resource}/${i.id}`;
          const existing = cache?.get(key) ?? {};
          const incoming = merge(existing, i);
          cache?.set(`${resource}/${i.id}`, incoming);
        });
      }

      return { data };
    } catch (error) {
      if (error instanceof Error && error.name === 'AbortError') {
        throw error;
      }
      throw new HttpError(
        (error as HttpError).body?.errors[0].detail ?? 'Error fetching records',
        (error as HttpError).body.status ?? 500,
        (error as HttpError).body,
      );
    }
  };
}
