import { BooleanInput, NumberInput, required, SelectInput, TextInput } from 'react-admin';
import ConditionalInput from '../../../Components/ConditionalInput';
import {
  FEED_DELIMITER_CHOICES,
  FEED_DISCRIMINATOR_CHOICES,
  FEED_DISCRIMINATOR_CSV,
  FEED_DISCRIMINATOR_XML,
  FEED_DISCRIMINATOR_XML_INDEX,
  FEED_TEXT_QUALIFIER_CHOICES,
} from '../../Merchant/utils';
import { InputAdornment } from '@mui/material';
import JsonApiHasOneReferenceInput from '../../../Components/JsonApiHasOneReferenceInput';
import { FeedResource } from '../../../Entities/Feed';
import { ReactElement } from 'react';

export default function FeedSummaryForm(): ReactElement {
  return (
    <>
      <JsonApiHasOneReferenceInput<FeedResource, 'merchant'>
        label="Merchant"
        source="relationships.merchant"
        reference="merchants"
        optionText="attributes.merchant_name"
        filterFields={['merchant_name']}
        isRequired
        validate={[required()]}
      />
      <TextInput label="URL" source="attributes.url" isRequired validate={required()} />
      <ConditionalInput
        field="attributes.url"
        condition={(value) => (value as string | undefined)?.startsWith('http') ?? false}
      >
        <BooleanInput
          label="Reduce HTTP Headers"
          source="attributes.not_to_use_request_extras"
          helperText="Enabling this reduces the number of HTTP headers sent in the request. This may be necessary for some feeds if the external server does not support all standard HTTP headers"
        />
      </ConditionalInput>
      <NumberInput
        label="Min Price (AUD)"
        source="attributes.min_price"
        defaultValue={0.01}
        InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
        isRequired
        validate={required()}
      />
      <NumberInput
        label="Max Price (AUD)"
        source="attributes.max_price"
        defaultValue={1000000000000000}
        InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
        isRequired
        validate={required()}
      />
      <BooleanInput
        label="Add GST"
        source="attributes.add_gst"
        helperText="Enabling this directs the scanner to add the Goods and Services Tax (GST) to all prices in the feed"
      />
      <SelectInput
        label="Feed Type"
        source="attributes.discriminator"
        choices={FEED_DISCRIMINATOR_CHOICES}
        isRequired
        validate={required()}
      />
      <ConditionalInput
        field="attributes.discriminator"
        condition={(value) => value === FEED_DISCRIMINATOR_CSV}
      >
        <BooleanInput
          label="CSV Has Headers"
          source="attributes.has_headers"
          helperText="Enabling this assumes the first row of the CSV file contains headers"
        />
      </ConditionalInput>
      <ConditionalInput
        field="attributes.discriminator"
        condition={(value) => value === FEED_DISCRIMINATOR_CSV}
      >
        <SelectInput
          label="CSV Delimiter"
          source="attributes.delimiter"
          choices={FEED_DELIMITER_CHOICES}
          isRequired
          validate={required()}
        />
      </ConditionalInput>
      <ConditionalInput
        field="attributes.discriminator"
        condition={(value) => value === FEED_DISCRIMINATOR_CSV}
      >
        <SelectInput
          label="CSV Text Qualifier"
          source="attributes.text_qualifier"
          choices={FEED_TEXT_QUALIFIER_CHOICES}
          isRequired
          validate={required()}
        />
      </ConditionalInput>
      <ConditionalInput
        field="attributes.discriminator"
        condition={(value) => value === FEED_DISCRIMINATOR_CSV}
      >
        <TextInput label="CSV Header Snapshot" source="attributes.header_snapshot" />
      </ConditionalInput>
      <ConditionalInput
        field="attributes.discriminator"
        condition={(value) => value === FEED_DISCRIMINATOR_XML_INDEX}
      >
        <TextInput
          label="XML Index Root Path"
          source="attributes.index_root_path"
          helperText="XPath to the root node in the index XML that contains the elements which contains URLs to the individual feed items"
        />
      </ConditionalInput>
      <ConditionalInput
        field="attributes.discriminator"
        condition={(value) => value === FEED_DISCRIMINATOR_XML_INDEX}
      >
        <TextInput
          label="XML Index Item Node Name"
          source="attributes.index_node_name"
          helperText="XPath to the node that contains the URL to the feed item, relative to the root node"
        />
      </ConditionalInput>
      <ConditionalInput
        field="attributes.discriminator"
        condition={(value) =>
          value === FEED_DISCRIMINATOR_XML || value === FEED_DISCRIMINATOR_XML_INDEX
        }
      >
        <TextInput
          label="XML Root Node Path"
          source="attributes.root_path"
          helperText="XPath to the root node that contains the feed items"
        />
      </ConditionalInput>
      <ConditionalInput
        field="attributes.discriminator"
        condition={(value) =>
          value === FEED_DISCRIMINATOR_XML || value === FEED_DISCRIMINATOR_XML_INDEX
        }
      >
        <TextInput
          label="XML Item Node Name"
          source="attributes.offer_node_name"
          helperText="XPath to the node that contains each feed item, relative to the root node"
        />
      </ConditionalInput>
    </>
  );
}
