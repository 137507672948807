import { Box, Button, ButtonProps, LinearProgress, Typography } from '@mui/material';
import { ReactElement, useEffect, useState } from 'react';
import {
  Confirm,
  FilterPayload,
  Identifier,
  RaRecord,
  useDeleteMany,
  useUpdateMany,
} from 'react-admin';
import { ignore } from '../../utils';
import useActionController from './useActionController';

interface SelfPaginatingBulkUpdateBaseProps {
  batchSize?: number;
  buttonLabel?: (total: number) => string;
  color?: ButtonProps['color'];
  confirm?: false | ((total: number) => string);
  disabled?: boolean;
  filter: FilterPayload;
  refresh?: () => void;
  resource: string;
  resetMs?: number;
}

type SelfPaginatingBulkUpdateProps<T extends Partial<RaRecord>> =
  SelfPaginatingBulkUpdateBaseProps & {
    changes: T;
    changeType?: 'update';
  };

type SelfPaginatingBulkDeleteProps = SelfPaginatingBulkUpdateBaseProps & {
  changes?: undefined;
  changeType?: 'delete';
};

export default function SelfPaginatingBulkAction<
  T extends Partial<RaRecord<Identifier>> = Partial<RaRecord<Identifier>>,
>({
  batchSize = 50,
  buttonLabel = (total): string =>
    changeType === 'update' ? `Update ${total} records` : `Delete ${total} records`,
  changes = undefined,
  changeType = 'update',
  color = 'primary',
  confirm = false,
  disabled = false,
  filter,
  resource,
  refresh = ignore,
  resetMs = 5000,
}: SelfPaginatingBulkUpdateProps<T> | SelfPaginatingBulkDeleteProps): ReactElement {
  const [updateMany, updateStatus] = useUpdateMany();
  const [deleteMany, deleteStatus] = useDeleteMany();
  const [running, setRunning] = useState<boolean>(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false);

  const action: (ids: Identifier[], onSuccess: () => void) => void =
    changeType === 'update'
      ? (ids, onSuccess) => updateMany(resource, { ids, data: changes }, { onSuccess })
      : (ids, onSuccess) => deleteMany(resource, { ids }, { onSuccess });

  const actionStatus = changeType === 'update' ? updateStatus : deleteStatus;

  const [start, paginationStatus] = useActionController({
    action,
    actionStatus,
    batchSize,
    filter,
    resource,
    refresh,
  });

  useEffect(() => {
    if (paginationStatus.complete) {
      refresh();

      const timeout = setTimeout(() => {
        setRunning(false);
      }, resetMs);

      return () => clearTimeout(timeout);
    }
  }, [paginationStatus.complete, refresh, resetMs]);

  if (paginationStatus.error) {
    return (
      <Button color="error" disabled>
        Unable to update records
      </Button>
    );
  }

  if (!running) {
    return (
      <>
        <Button
          variant="contained"
          color={color}
          disabled={paginationStatus.total === 0 || disabled}
          onClick={() => {
            if (confirm) {
              setIsConfirmOpen(true);
            } else {
              setRunning(true);
              start();
            }
          }}
        >
          <span>{buttonLabel(paginationStatus.total)}</span>
        </Button>
        {confirm && (
          <Confirm
            content={confirm(paginationStatus.total)}
            isOpen={isConfirmOpen}
            onClose={() => setIsConfirmOpen(false)}
            onConfirm={() => {
              setIsConfirmOpen(false);
              setRunning(true);
              start();
            }}
            title={
              (changeType === 'update' ? 'Update' : 'Delete') +
              ` ${paginationStatus.total} records?`
            }
          />
        )}
      </>
    );
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', padding: '6px' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress
          color={paginationStatus.complete ? 'success' : 'primary'}
          sx={{ width: '100%' }}
          variant="determinate"
          value={paginationStatus.progress}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          sx={{ color: 'text.secondary' }}
        >{`${Math.round(paginationStatus.progress)}%`}</Typography>
      </Box>
    </Box>
  );
}
