import {
  AutocompleteArrayInput,
  DatagridConfigurable,
  List,
  ReferenceArrayInput,
  ReferenceField,
  TextArrayInput,
  TextField,
  TextInput,
} from 'react-admin';
import { ReactElement } from 'react';
import ActionPanel from './ActionPanel';

const filters = [
  <ReferenceArrayInput
    key="merchant_id"
    label="Merchant"
    source="merchant.id"
    reference="merchants"
  >
    <AutocompleteArrayInput
      label="Merchant"
      optionText="attributes.merchant_name"
      filterToQuery={(query: string) =>
        query.match(/^\d+$/)
          ? { or: { id: query, contains: { merchant_name: query } } }
          : { contains: { merchant_name: query } }
      }
    />
  </ReferenceArrayInput>,
  <ReferenceArrayInput
    key="category_id"
    label="Category"
    source="category.id"
    reference="categories"
  >
    <AutocompleteArrayInput
      label="Category"
      optionText="attributes.name"
      filterToQuery={(query: string) =>
        query.match(/^\d+$/)
          ? { or: { id: query, contains: { name: query } } }
          : { contains: { name: query } }
      }
    />
  </ReferenceArrayInput>,
  <TextArrayInput
    key="external_category"
    label="External Category Contains"
    source="contains.external_category"
  />,
  <TextArrayInput
    key="external_category_not_contains"
    label="External Category Does Not Contain"
    source="not.contains.external_category"
  />,
  <TextInput
    key="external_category_exact"
    label="External Category Is Exactly"
    source="equals.external_category"
  />,
];

export default function MappingRuleList(): ReactElement {
  return (
    <List sort={{ field: 'id', order: 'DESC' }} perPage={25} filters={filters}>
      <DatagridConfigurable expand={<ActionPanel />}>
        <TextField source="id" />
        <ReferenceField
          label="Merchant"
          source="relationships.merchant.data.id"
          reference="merchants"
        >
          <TextField source="attributes.merchant_name" />
        </ReferenceField>
        <TextField label="External Category" source="attributes.external_category" />
        <ReferenceField
          label="Category"
          source="relationships.category.data.id"
          reference="categories"
        >
          <TextField source="attributes.full_name" />
        </ReferenceField>
      </DatagridConfigurable>
    </List>
  );
}
