import { ReactElement } from 'react';
import { Edit, TabbedForm } from 'react-admin';
import EditToolbar from '../../Components/EditToolbar';
import FeedFieldsForm from './Forms/FeedFieldsForm';
import FeedSummaryForm from './Forms/FeedSummaryForm';

export default function FeedEdit(): ReactElement {
  return (
    <Edit mutationMode="pessimistic">
      <TabbedForm toolbar={<EditToolbar />}>
        <TabbedForm.Tab label="Summary">
          <FeedSummaryForm />
        </TabbedForm.Tab>
        <TabbedForm.Tab label="Fields">
          <FeedFieldsForm />
        </TabbedForm.Tab>
      </TabbedForm>
    </Edit>
  );
}
