import { ReactElement } from 'react';
import {
  AutocompleteInput,
  Edit,
  Labeled,
  ReferenceField,
  ReferenceInput,
  SimpleForm,
  TextField,
} from 'react-admin';
import EditToolbar from '../../Components/EditToolbar';
import ActionPanel from './ActionPanel';

export default function MappingRulesEdit(): ReactElement {
  return (
    <Edit>
      <SimpleForm toolbar={<EditToolbar />}>
        <Labeled>
          <ReferenceField
            reference="merchants"
            source="relationships.merchant.data.id"
            label="Merchant"
          />
        </Labeled>
        <Labeled>
          <TextField source="attributes.external_category" label="External Category" />
        </Labeled>
        <ReferenceInput
          reference="categories"
          source="relationships.category.data.id"
          label="Category"
          filter={{ level: 0 }}
        >
          <AutocompleteInput
            label="Category"
            optionText="attributes.full_name"
            filterToQuery={(query: string) =>
              query.match(/^\d+$/)
                ? { or: { id: query, contains: { name: query } } }
                : { contains: { name: query } }
            }
          />
        </ReferenceInput>
        <ActionPanel />
      </SimpleForm>
    </Edit>
  );
}
