import { ReactElement } from 'react';
import { Create, SimpleForm } from 'react-admin';
import FeedSummaryForm from './Forms/FeedSummaryForm';
import EditToolbar from '../../Components/EditToolbar';

export default function FeedCreate(): ReactElement {
  return (
    <Create redirect="edit">
      <SimpleForm toolbar={<EditToolbar />}>
        <FeedSummaryForm />
      </SimpleForm>
    </Create>
  );
}
