import { AutocompleteInput, required, useGetList } from 'react-admin';
import { ReactElement, useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import DraftMappingRuleResource from '../../Entities/DraftMappingRule';
import { entry } from '../../utils';

export default function ExternalCategoryAutocompleteInput(): ReactElement {
  const merchantId = useWatch({ name: 'relationships.merchant.data.id' });
  const { isPending, data } = useGetList<DraftMappingRuleResource>('draft_mapping_rules', {
    filter: { merchant: { id: merchantId } },
    sort: { field: 'attributes.offer_count', order: 'DESC' },
    pagination: { page: 1, perPage: 100 },
  });

  const [choices, setChoices] = useState<Record<string, string>[]>([]);

  useEffect(() => {
    setChoices(data?.map((item) => entry(item.attributes.external_category)) ?? []);
  }, [data]);

  return (
    <AutocompleteInput
      label="Merchant Category"
      source="attributes.external_category"
      choices={choices}
      disabled={!merchantId || isPending}
      filterToQuery={(query) => ({ contains: { external_category: query } })}
      onCreate={(value) => {
        if (!value) {
          return undefined;
        }

        setChoices([...new Set([...choices, entry(value)])]);

        return entry(value);
      }}
      isRequired
      validate={required()}
    />
  );
}
