/**
 * A simple mutex implementation for async (but not multi-threaded /
 * multi-worker) code. This is useful for preventing multiple async functions
 * on the same thread from using a shared resource concurrently. It is not able
 * to prevent concurrent access from multiple threads or workers, so do not use
 * it in a multi-threaded or multi-worker environment.
 */
export default class Mutex {
  private locked = false;

  public withLock<T>(fn: () => Promise<T>): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      // check every 10ms if the lock is free
      const interval = setInterval(() => {
        if (!this.locked) {
          this.locked = true;

          clearInterval(interval);

          fn()
            .then((t) => {
              this.locked = false;
              resolve(t);
            })
            .catch((e) => {
              this.locked = false;
              reject(e);
            });
        }
      }, 10);
    });
  }
}
