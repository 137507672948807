import { ReactElement } from 'react';
import {
  TabbedForm,
  TextInput,
  maxLength,
  PasswordInput,
  TabbedFormViewProps,
  BooleanInput,
  minLength,
  required,
} from 'react-admin';

export default function MerchantConfigTab(
  props: TabbedFormViewProps & { isCreateView?: boolean },
): ReactElement {
  return (
    <TabbedForm.Tab label="Merchant Config" {...props}>
      <BooleanInput
        label="Block International Clicks?"
        source="attributes.block_international_clicks"
      />
      <BooleanInput label="Track Price History?" source="attributes.price_tracking" />
      <TextInput
        autoComplete="new-password"
        label="Username"
        source="attributes.username"
        validate={[maxLength(50), required()]}
        isRequired
      />
      <PasswordInput
        autoComplete="new-password"
        label="Password"
        placeholder="**********"
        source="attributes.password"
        validate={props.isCreateView ? [minLength(8), required()] : minLength(8)}
        isRequired={Boolean(props.isCreateView)}
      />
      <TextInput
        fullWidth
        label="UTM Parameters"
        source="attributes.utm_parameters"
        validate={maxLength(150)}
      />
    </TabbedForm.Tab>
  );
}
