import {
  BooleanInput,
  Edit,
  ImageField,
  Labeled,
  NumberField,
  NumberInput,
  TabbedForm,
  TextInput,
} from 'react-admin';
import { Box, Divider, Grid, InputAdornment } from '@mui/material';
import { ProductResource } from '../../Entities/Product';
import EditToolbar from '../../Components/EditToolbar';
import TzDateField from '../../Components/TzDate/TzDateField';
import SadPanda from '../../Components/SadPanda';
import { ReactElement } from 'react';
import JsonApiHasOneReferenceInput from '../../Components/JsonApiHasOneReferenceInput';

export default function ProductEdit(): ReactElement {
  return (
    <Edit<ProductResource> mutationMode="pessimistic">
      <TabbedForm toolbar={<EditToolbar />}>
        <TabbedForm.Tab label="Details">
          <Grid container spacing={2}>
            <Grid item xs={12} xl={3}>
              <Labeled>
                <ImageField label="Image" source="attributes.image_url" />
              </Labeled>
            </Grid>
            <Grid item xs={12} xl={3}>
              <Box flex={1}>
                <Labeled>
                  <NumberField label="Shop Count" source="attributes.shop_count" />
                </Labeled>
              </Box>
              <Box flex={1}>
                <Labeled>
                  <NumberField label="Views" source="attributes.views_count" />
                </Labeled>
              </Box>
              <Box flex={1}>
                <Labeled>
                  <TzDateField label="Last Edited" source="attributes.edited" showTime />
                </Labeled>
              </Box>
            </Grid>
          </Grid>
          <Divider flexItem />
          <Grid container spacing={2}>
            <Grid item xs={12} xl={4}>
              <Box flex={1}>
                <TextInput label="Name" source="attributes.name" fullWidth />
              </Box>
              <Box flex={1}>
                <TextInput label="Model" source="attributes.model" fullWidth />
              </Box>
              <Box flex={1}>
                <TextInput label="Manufacturer" source="attributes.manufacturer" fullWidth />
              </Box>
              <Box flex={1}>
                <TextInput
                  label="Description"
                  source="attributes.description"
                  multiline
                  fullWidth
                />
              </Box>
              <Box flex={1}>
                <TextInput label="Keywords" source="attributes.keywords" multiline fullWidth />
              </Box>
              <Box flex={1}>
                <JsonApiHasOneReferenceInput<ProductResource, 'category', 'categories'>
                  label="Category"
                  optionText="attributes.full_name"
                  source="relationships.category"
                  reference="categories"
                />
              </Box>
            </Grid>
            <Grid item xs={12} xl={2}>
              <NumberInput
                label="Min Price (AUD)"
                source="attributes.min_price"
                step={0.01}
                fullWidth
                InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
              />
              <NumberInput
                label="Max Price (AUD)"
                source="attributes.max_price"
                step={0.01}
                fullWidth
                InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
              />

              <NumberInput
                label="Future Publishing ID"
                source="attributes.future_publishing_id"
                fullWidth
              />
              <TextInput label="MPN" source="attributes.mpn" fullWidth />
              <TextInput label="UPC" source="attributes.upc" fullWidth />
            </Grid>
            <Grid item xs={12} xl={6}>
              <BooleanInput label="Active?" source="attributes.status" />
              <BooleanInput label="Is Prioritized?" source="attributes.is_prioritized" />
              <Labeled>
                <SadPanda
                  label="Upload New Image"
                  ticket="https://purch1.atlassian.net/browse/GET-672"
                />
              </Labeled>
            </Grid>
          </Grid>
        </TabbedForm.Tab>
        <TabbedForm.Tab label="Category Details">
          <JsonApiHasOneReferenceInput<ProductResource, 'category', 'categories'>
            label="Category"
            optionText="attributes.full_name"
            source="relationships.category"
            reference="categories"
          />
          <SadPanda />
        </TabbedForm.Tab>
      </TabbedForm>
    </Edit>
  );
}
