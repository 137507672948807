import { ReactElement } from 'react';
import {
  TabbedForm,
  TextInput,
  maxLength,
  TabbedFormViewProps,
  useCanAccess,
  required,
} from 'react-admin';
import JsonApiHasOneReferenceInput from '../../../Components/JsonApiHasOneReferenceInput';
import { MerchantResource } from '../../../Entities/Merchant';

export default function AddressTab(props: TabbedFormViewProps): ReactElement {
  const regionsAccess = useCanAccess({ resource: 'regions', action: 'list' });
  const canListRegions = regionsAccess.canAccess ?? false;

  return (
    <TabbedForm.Tab label="Address" {...props}>
      <TextInput label="Street Address" source="attributes.address" validate={maxLength(255)} />
      <JsonApiHasOneReferenceInput<MerchantResource, 'state'>
        label="State"
        optionText="attributes.name"
        reference="states"
        source="relationships.state"
      />
      <TextInput label="Post Code" source="attributes.postal_code" validate={maxLength(50)} />
      <TextInput label="City" source="attributes.city" validate={maxLength(50)} />
      {canListRegions && (
        <JsonApiHasOneReferenceInput<MerchantResource, 'region'>
          defaultValue="1"
          isRequired
          validate={[required()]}
          label="Region"
          optionText="attributes.name"
          reference="regions"
          source="relationships.region"
        />
      )}
    </TabbedForm.Tab>
  );
}
