import { ReactElement } from 'react';
import {
  BooleanField,
  DatagridConfigurable,
  List,
  NumberField,
  ReferenceField,
  SelectField,
  TextField,
  UrlField,
} from 'react-admin';
import { DEFAULT_LIST_PAGE_PAGINATION_LIMIT } from '../../utils';
import TruncatedTextField from '../../Components/TruncatedTextField';
import {
  FEED_DELIMITER_CHOICES,
  FEED_DISCRIMINATOR_CHOICES,
  FEED_TEXT_QUALIFIER_CHOICES,
} from '../Merchant/utils';

export default function FeedList(): ReactElement {
  return (
    <List perPage={DEFAULT_LIST_PAGE_PAGINATION_LIMIT} sort={{ field: 'id', order: 'DESC' }}>
      <DatagridConfigurable
        omit={[
          'attributes.not_to_use_request_extras',
          'attributes.delimiter',
          'attributes.text_qualifier',
          'attributes.has_headers',
          'attributes.header_snapshot',
          'attributes.index_root_path',
          'attributes.index_node_name',
          'attributes.root_path',
          'attributes.offer_node_name',
        ]}
      >
        <TextField label="ID" source="id" />
        <ReferenceField
          label="Merchant"
          source="relationships.merchant.data.id"
          reference="merchants"
        >
          <TextField source="attributes.merchant_name" />
        </ReferenceField>
        <TruncatedTextField label="Notes" source="attributes.notes" length={50} />
        <UrlField label="Feed URL" source="attributes.url" />
        <BooleanField label="Reduced Headers?" source="attributes.not_to_use_request_extras" />
        <NumberField label="Min Price (AUD)" source="attributes.min_price" />
        <NumberField label="Max Price (AUD)" source="attributes.max_price" />
        <BooleanField label="Add GST?" source="attributes.add_gst" />
        <SelectField
          label="Feed Type"
          source="attributes.discriminator"
          choices={FEED_DISCRIMINATOR_CHOICES}
        />
        <SelectField
          label="CSV Delimiter"
          source="attributes.delimiter"
          choices={FEED_DELIMITER_CHOICES}
        />
        <SelectField
          label="CSV Text Qualifier"
          source="attributes.text_qualifier"
          choices={FEED_TEXT_QUALIFIER_CHOICES}
        />
        <BooleanField label="CSV Contains Headers?" source="attributes.has_headers" />
        <TextField label="CSV Header Snapshot" source="attributes.header_snapshot" />
        <TextField label="XML Index Root Path" source="attributes.index_root_path" />
        <TextField label="XML Index Item Node Name" source="attributes.index_node_name" />
        <TextField label="XML Root Path" source="attributes.root_path" />
        <TextField label="XML Item Node Name" source="attributes.offer_node_name" />
      </DatagridConfigurable>
    </List>
  );
}
