import { Create, TabbedForm } from 'react-admin';
import { MerchantResource } from '../../Entities/Merchant';
import SummaryTab from './Forms/SummaryTab';
import ContactInfoTab from './Forms/ContactInfoTab';
import AddressTab from './Forms/AddressTab';
import BillingInfoTab from './Forms/BillingInfoTab';
import MerchantConfigTab from './Forms/MerchantConfigTab';
import { ReactElement } from 'react';

export default function MerchantCreate(): ReactElement {
  return (
    <Create<MerchantResource>>
      <TabbedForm>
        <SummaryTab />
        <MerchantConfigTab isCreateView />
        <AddressTab />
        <ContactInfoTab />
        <BillingInfoTab />
      </TabbedForm>
    </Create>
  );
}
