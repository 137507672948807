import { ReactElement } from 'react';
import {
  TabbedFormViewProps,
  TabbedForm,
  TextInput,
  maxLength,
  required,
  PasswordInput,
  minLength,
} from 'react-admin';
import { useWatch } from 'react-hook-form';

export default function MerchantConfigTab(props: TabbedFormViewProps): ReactElement {
  const id = useWatch({ name: 'id' }) as string | undefined;
  return (
    <TabbedForm.Tab label="Registration User Config" {...props}>
      <TextInput label="Type" source="attributes.type" validate={maxLength(255)} />
      <TextInput
        label="Username"
        source="attributes.username"
        validate={[maxLength(50), required()]}
        isRequired
      />
      <PasswordInput
        label="Password"
        placeholder="**********"
        source="attributes.password"
        validate={id === undefined ? [minLength(8), required()] : minLength(8)}
        isRequired={id === undefined}
      />
      <TextInput label="Feed" source="attributes.feed" validate={maxLength(255)} />
    </TabbedForm.Tab>
  );
}
