import { Card, CardContent, CardHeader } from '@mui/material';
import { ReactElement, useState } from 'react';
import {
  ListContextProvider,
  Pagination,
  ResourceContextProvider,
  SortPayload,
  useGetList,
  UseListValue,
} from 'react-admin';

export interface ListWidgetProps {
  children: React.ReactNode;
  defaultSort?: SortPayload;
  filter?: Record<string, unknown>;
  page?: number;
  pagination?: React.ReactNode;
  perPage?: number;
  resource: string;
  title: string;
}

// no-op function, so we can pass it as a default value for some context values
function noop(): undefined {
  return undefined;
}

export default function ListWidget({
  children,
  defaultSort = { field: 'id', order: 'DESC' },
  filter = {},
  page = 1,
  pagination = <Pagination />,
  perPage = 5,
  resource,
  title,
}: ListWidgetProps): ReactElement {
  const [currentPage, setPage] = useState(page);
  const [currentPerPage, setPerPage] = useState(perPage);
  const [sort, setSort] = useState<SortPayload>(defaultSort);

  const { data, isLoading, total, error, isFetching, isPending, refetch } = useGetList(resource, {
    filter,
    pagination: { page: currentPage, perPage: currentPerPage },
    sort,
  });

  const listContext: UseListValue = {
    data: data ?? [],
    displayedFilters: {},
    error,
    filterValues: {},
    hasNextPage: total ? currentPage * currentPerPage < total : true,
    hasPreviousPage: currentPage > 1,
    hideFilter: noop,
    isFetching,
    isLoading,
    isPending: isPending as false,
    onSelect: noop,
    onSelectAll: noop,
    onToggleItem: noop,
    onUnselectItems: noop,
    page: currentPage,
    perPage: currentPerPage,
    refetch,
    resource,
    selectedIds: [],
    setFilters: noop,
    setPage,
    setPerPage,
    setSort,
    showFilter: noop,
    sort,
    total: total ?? 0,
  };

  return (
    <Card>
      <CardHeader title={title} />
      <CardContent>
        <ResourceContextProvider value={resource}>
          <ListContextProvider value={listContext}>
            {children}
            {pagination}
          </ListContextProvider>
        </ResourceContextProvider>
      </CardContent>
    </Card>
  );
}
