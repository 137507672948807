import { ReactElement } from 'react';
import {
  AutocompleteArrayInput,
  CreateButton,
  Datagrid,
  List,
  ListProps,
  NumberField,
  NumberInput,
  ReferenceArrayInput,
  ReferenceField,
  SelectArrayInput,
  TextArrayInput,
  TextField,
  WithRecord,
} from 'react-admin';
import LargePagination from '../../Components/LargePagination';
import DraftMappingRuleResource from '../../Entities/DraftMappingRule';

const filters = [
  <ReferenceArrayInput
    key="merchant_id"
    label="Merchant"
    source="merchant.id"
    reference="merchants"
  >
    <AutocompleteArrayInput
      label="Merchant"
      optionText="attributes.merchant_name"
      filterToQuery={(query: string) =>
        query.match(/^\d+$/)
          ? { or: { id: query, contains: { merchant_name: query } } }
          : { contains: { merchant_name: query } }
      }
    />
  </ReferenceArrayInput>,
  <ReferenceArrayInput
    key="merchant_status_id"
    label="Merchant Status"
    source="merchant.merchant_status.id"
    reference="merchant_statuses"
  >
    <SelectArrayInput label="Merchant Status" optionText="attributes.name" />
  </ReferenceArrayInput>,
  <TextArrayInput
    key="contains_external_category"
    label="External Category Contains"
    source="contains.external_category"
  />,
  <TextArrayInput
    key="not_contains_external_category"
    label="External Category Does Not Contain"
    source="not.contains.external_category"
  />,
  <NumberInput
    key="greaterThan_offer_count"
    label="Offer Count Greater Than"
    source="greaterThan.offer_count"
  />,
  <NumberInput
    key="lessThan_offer_count"
    label="Offer Count Less Than"
    source="lessThan.offer_count"
  />,
];

export default function DraftsList(props: ListProps): ReactElement {
  return (
    <List
      {...props}
      filters={filters}
      filterDefaultValues={{ merchant: { merchant_status: { id: '1' } } }}
      pagination={<LargePagination />}
      perPage={25}
      sort={{ field: 'attributes.offer_count', order: 'DESC' }}
    >
      <Datagrid bulkActionButtons={false}>
        <ReferenceField
          label="Merchant"
          source="relationships.merchant.data.id"
          reference="merchants"
        >
          <TextField source="attributes.merchant_name" />
        </ReferenceField>
        <TextField label="External Category" source="attributes.external_category" />
        <NumberField label="Offer Count" source="attributes.offer_count" />
        <WithRecord
          render={(record: DraftMappingRuleResource) => (
            <CreateButton
              resource="mapping_rules"
              label="Create New Mapping Rule"
              state={{
                record: {
                  attributes: {
                    external_category: record.attributes.external_category,
                  },
                  relationships: {
                    merchant: {
                      data: {
                        id: record.relationships.merchant.data?.id,
                        type: 'merchants',
                      },
                    },
                  },
                },
              }}
            />
          )}
        />
      </Datagrid>
    </List>
  );
}
