import { isEqual, round } from 'lodash';
import { useEffect, useState } from 'react';
import { FilterPayload, Identifier, useGetList, useNotify } from 'react-admin';
import { ignore } from '../../utils';

export interface useActionControllerOptions {
  action: (ids: Identifier[], onSuccess: () => void) => void;
  actionStatus: { isPending: boolean; error: unknown };
  batchSize?: number;
  filter: FilterPayload;
  resource: string;
  refresh?: () => void;
}

export default function useActionController({
  action,
  actionStatus,
  batchSize = 5,
  filter,
  resource,
  refresh = ignore,
}: useActionControllerOptions): [
  () => void,
  {
    complete: boolean;
    current: number;
    error: unknown;
    progress: number;
    refetch: () => void;
    total: number;
  },
] {
  const notify = useNotify();
  const listStatus = useGetList(resource, {
    filter,
    pagination: {
      page: 1,
      perPage: batchSize,
    },
  });

  const [userRequestedAction, setUserRequestedAction] = useState<boolean>(false);
  const [initialTotal, setInitialTotal] = useState<number | null>(null);
  const [progress, setProgress] = useState<number>(0);
  const [complete, setComplete] = useState<boolean>(false);

  const [inProgressIds, setInProgressIds] = useState<Identifier[]>([]);

  // Initialize the state of this controller
  useEffect(() => {
    if (initialTotal === null && (listStatus.total ?? 0) > 0) {
      setInitialTotal(listStatus.total ?? null);
      return;
    }
  }, [listStatus, initialTotal]);

  // Process the action while getList returns records to update. Automatically
  // detects when running the action is not making progress (e.g. incorrect
  // filter) and cancels out of the loop.
  useEffect(() => {
    if (initialTotal === null) return;
    if (listStatus.total === undefined) return;
    if (userRequestedAction === false) return;
    if (actionStatus.isPending) return;

    const currentIds = listStatus.data?.map((record) => record.id) ?? [];

    const remaining = initialTotal - listStatus.total;
    const newProgress = Math.min(100, round((remaining / initialTotal) * 100, 2));

    if (newProgress < 100 && complete) {
      setComplete(false);
    }

    if (newProgress !== progress) {
      setProgress(newProgress);
    }

    if (currentIds.length === 0) return;
    if (isEqual(currentIds, inProgressIds)) return;

    if (inProgressIds.length > 0 && currentIds.length > 0 && listStatus.total === initialTotal) {
      setUserRequestedAction(false);
      console.error(
        "ActionController: The list of records didn't change after the last update. This is likely a bug.",
        filter,
        listStatus.data,
      );
      notify('Error updating records', {
        type: 'error',
        autoHideDuration: 10000,
      });

      return;
    }

    action(currentIds, listStatus.refetch);
    setInProgressIds(currentIds);
  }, [
    action,
    actionStatus,
    complete,
    filter,
    inProgressIds,
    initialTotal,
    listStatus,
    notify,
    progress,
    resource,
    userRequestedAction,
  ]);

  // Cleanup the state when the action is done
  useEffect(() => {
    if (userRequestedAction && progress >= 100) {
      setComplete(true);
      setInProgressIds([]);
      setInitialTotal(null);
      setUserRequestedAction(false);
      refresh();
    }
  }, [progress, refresh, userRequestedAction]);

  return [
    () => {
      setComplete(false);
      setProgress(0);
      setUserRequestedAction(true);
    },
    {
      current: listStatus.total ?? 0,
      complete,
      error: listStatus.error ?? actionStatus.error ?? null,
      progress,
      refetch: listStatus.refetch,
      total: initialTotal ?? 0,
    },
  ];
}
