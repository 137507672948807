import {
  CreateParams,
  CreateResult,
  fetchUtils,
  HttpError,
  Identifier,
  RaRecord,
} from 'react-admin';
import { defaultHeaders, withIncludes } from './utils';
import ExpiringCache from '../ExpiringCache';
import { JsonApiResource, JsonApiSingleResponse } from './JsonApiTypes';
import { AuthProvider } from './AuthProvider';

export default function create(
  apiUrl: string,
  httpClient = fetchUtils.fetchJson,
  canAccess: typeof AuthProvider.canAccess = async () => true,
  cache: ExpiringCache | undefined = undefined,
) {
  return async <T extends RaRecord<Identifier>>(
    resource: string,
    params: CreateParams<T>,
  ): Promise<CreateResult<T & JsonApiResource>> => {
    const payload = { data: params.data } as Partial<JsonApiResource>;
    payload.data.type = resource;

    const query = await withIncludes(resource, canAccess);
    const url = `${apiUrl}/${resource}?${query}`;

    try {
      const response = await httpClient(url, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: defaultHeaders,
      });
      const result = response.json as JsonApiSingleResponse;
      const { data } = result;

      if (!data) {
        throw new HttpError(
          'The server returned an empty response',
          response.status,
          response.body,
        );
      }

      cache?.set(`${resource}/${data.id}`, result);

      return { data } as CreateResult<T & JsonApiResource>;
    } catch (error) {
      throw new HttpError(
        (error as HttpError).body?.errors[0].detail ?? 'Error creating record',
        (error as HttpError).body.status ?? 500,
        (error as HttpError).body,
      );
    }
  };
}
