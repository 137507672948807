import { Box, Grid, InputAdornment, Switch } from '@mui/material';
import {
  BooleanInput,
  Configurable,
  ImageField,
  Labeled,
  NumberInput,
  SimpleForm,
  SimpleFormProps,
  TextInput,
  usePreference,
} from 'react-admin';
import EditToolbar from '../../Components/EditToolbar';
import SadPanda from '../../Components/SadPanda';
import { ReactElement } from 'react';
import JsonApiHasOneReferenceInput from '../../Components/JsonApiHasOneReferenceInput';
import { CategoryResource } from '../../Entities/Category';

const fields = {
  'attributes.dictionary': 'Dictionary',
  'attributes.exclude': 'Exclude',
  'attributes.footer_links': 'Footer Links',
  'attributes.icon_url': 'Icon',
  'attributes.image_url': 'Image',
  'attributes.is_active': 'Active?',
  'attributes.is_adult_content': 'Adult Content?',
  'attributes.is_offer_comparison_allowed': 'Offer Comparison Allowed?',
  'attributes.is_promoted': 'Promoted?',
  'attributes.keywords': 'Keywords',
  'attributes.min_price': 'Min Price (AUD)',
  'attributes.name': 'Name',
  'attributes.old_name': 'Old Name',
  'attributes.search_keywords': 'Search Keywords',
  'attributes.singularized_name': 'Singularized Name',
  'attributes.word_rank': 'Word Rank',
  'relationships.parent': 'Parent Category',
};

const defaultOmit = [
  'attributes.dictionary',
  'attributes.footer_links',
  'attributes.is_active',
  'attributes.is_adult_content',
  'attributes.is_promoted',
  'attributes.keywords',
  'attributes.old_name',
  'attributes.search_keywords',
  'attributes.singularized_name',
  'attributes.word_rank',
];

function CategoryConfigurableForm(): ReactElement {
  const [omit] = usePreference('categories.form.omit', defaultOmit);

  function includeSectionIfAnyNotOmitted(...fieldNames: (keyof typeof fields)[]) {
    return (section: ReactElement): ReactElement | null =>
      fieldNames.some((fieldName) => !omit.includes(fieldName)) ? section : null;
  }

  return (
    <Grid container spacing={2}>
      {includeSectionIfAnyNotOmitted(
        'attributes.image_url',
        'attributes.icon_url',
      )(
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {omit.includes('attributes.image_url') ? null : (
              <Grid item xs={12} xl={6}>
                <Box flex="1">
                  <Labeled>
                    <ImageField
                      label={fields['attributes.image_url']}
                      source="attributes.image_url"
                      sx={{ img: { maxHeight: '150px', maxWidth: '100%' } }}
                    />
                  </Labeled>
                </Box>
                <Box flex="1">
                  <Labeled label="Upload New Image">
                    <SadPanda ticket="https://purch1.atlassian.net/browse/GET-672" />
                  </Labeled>
                </Box>
              </Grid>
            )}
            {omit.includes('attributes.icon_url') ? null : (
              <Grid item xs={12} xl={6}>
                <Box flex="1">
                  <Labeled>
                    <ImageField
                      label={fields['attributes.icon_url']}
                      source="attributes.icon_url"
                      sx={{ img: { maxHeight: '150px', maxWidth: '100%' } }}
                    />
                  </Labeled>
                </Box>
                <Box flex="1">
                  <Labeled label="Upload New Icon">
                    <SadPanda ticket="https://purch1.atlassian.net/browse/GET-672" />
                  </Labeled>
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>,
      )}
      {includeSectionIfAnyNotOmitted(
        'attributes.name',
        'attributes.old_name',
        'attributes.min_price',
        'relationships.parent',
      )(
        <Grid item xs={12} xl={9}>
          {omit.includes('attributes.name') ? null : (
            <TextInput label={fields['attributes.name']} source="attributes.name" />
          )}
          {omit.includes('attributes.old_name') ? null : (
            <TextInput label={fields['attributes.old_name']} source="attributes.old_name" />
          )}
          {omit.includes('attributes.min_price') ? null : (
            <NumberInput
              label={fields['attributes.min_price']}
              source="attributes.min_price"
              step={1}
              defaultValue={0}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
          )}
          {omit.includes('relationships.parent') ? null : (
            <JsonApiHasOneReferenceInput<CategoryResource, 'parent', 'categories'>
              label={fields['relationships.parent']}
              optionText="attributes.full_name"
              source="relationships.parent"
              reference="categories"
              referenceInputProps={{ filter: { any: { level: [1, 2] } } }}
            />
          )}
        </Grid>,
      )}
      {includeSectionIfAnyNotOmitted(
        'attributes.is_active',
        'attributes.is_offer_comparison_allowed',
        'attributes.is_adult_content',
        'attributes.is_promoted',
      )(
        <Grid item xs={12} xl={3}>
          {omit.includes('attributes.is_active') ? null : (
            <BooleanInput label={fields['attributes.is_active']} source="attributes.is_active" />
          )}
          {omit.includes('attributes.is_offer_comparison_allowed') ? null : (
            <BooleanInput
              label={fields['attributes.is_offer_comparison_allowed']}
              source="attributes.is_offer_comparison_allowed"
            />
          )}
          {omit.includes('attributes.is_adult_content') ? null : (
            <BooleanInput
              label={fields['attributes.is_adult_content']}
              source="attributes.is_adult_content"
            />
          )}
          {omit.includes('attributes.is_promoted') ? null : (
            <BooleanInput
              label={fields['attributes.is_promoted']}
              source="attributes.is_promoted"
            />
          )}
        </Grid>,
      )}
      {includeSectionIfAnyNotOmitted(
        'attributes.dictionary',
        'attributes.exclude',
        'attributes.footer_links',
        'attributes.keywords',
        'attributes.search_keywords',
        'attributes.singularized_name',
        'attributes.word_rank',
      )(
        <Grid item xs={12}>
          {omit.includes('attributes.dictionary') ? null : (
            <TextInput label={fields['attributes.dictionary']} source="attributes.dictionary" />
          )}
          {omit.includes('attributes.exclude') ? null : (
            <TextInput label={fields['attributes.exclude']} source="attributes.exclude" />
          )}
          {omit.includes('attributes.footer_links') ? null : (
            <TextInput label={fields['attributes.footer_links']} source="attributes.footer_links" />
          )}
          {omit.includes('attributes.keywords') ? null : (
            <TextInput label={fields['attributes.keywords']} source="attributes.keywords" />
          )}
          {omit.includes('attributes.search_keywords') ? null : (
            <TextInput
              label={fields['attributes.search_keywords']}
              source="attributes.search_keywords"
            />
          )}
          {omit.includes('attributes.singularized_name') ? null : (
            <TextInput
              label={fields['attributes.singularized_name']}
              source="attributes.singularized_name"
            />
          )}
          {omit.includes('attributes.word_rank') ? null : (
            <TextInput label={fields['attributes.word_rank']} source="attributes.word_rank" />
          )}
        </Grid>,
      )}
    </Grid>
  );
}

function CategoryFormEditor(): ReactElement {
  const [omit, setOmit] = usePreference('categories.form.omit', defaultOmit);

  return (
    <>
      {Object.entries(fields).map(([field, label]) => (
        <Box key={field}>
          <Switch
            checked={!omit.includes(field)}
            onChange={(event) =>
              setOmit(
                event.target.checked
                  ? omit.filter((omitField) => omitField !== field)
                  : Array.from(new Set([...omit, field])),
              )
            }
          />
          <span>{label}</span>
        </Box>
      ))}
    </>
  );
}

export type CategoryFormProps = Omit<SimpleFormProps, 'children'> & { preferenceKey?: string };

export default function CategoryForm({
  preferenceKey = 'categories.form',
  ...props
}: CategoryFormProps): ReactElement {
  return (
    <Configurable
      editor={<CategoryFormEditor />}
      preferenceKey={preferenceKey}
      sx={{
        // Ensure the form is always full width, with a small margin around it
        // to allow the outline to be visible when the configuration editor is
        // open.
        '&.RaConfigurable-root': { margin: '2px', width: 'calc(100% - 4px)' },
      }}
    >
      <SimpleForm {...props} toolbar={<EditToolbar />}>
        <CategoryConfigurableForm />
      </SimpleForm>
    </Configurable>
  );
}
